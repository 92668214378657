<template>
<div class="auth-layout-wrap isaloginbck" :style="{ backgroundImage: 'url(' + bgImage + ')' }">
      <div class="container">
        <div class="d-flex justify-content-center h-100">
          <div class="card">
 <div class="d-flex justify-content-center">
        <ISALanguageSelector style="top: 0; right: 2rem;position: absolute"/>
        <img :src="IMGService ? IMGService.isalogo : ''" class="brand_logo" alt="Logo" style="margin-top: 20px" />
        <div class="form_container">
            <form style="margin-top: 20px">
                <div class="user-thumb text-center m-b-30">                     
                    <img :src="IMGService ? IMGService.isalock : ''"
                         class="rounded-circle img-thumbnail"
                         alt="thumbnail"
                         style="height: 80px; padding-top:-10px"
                         v-b-toggle.collapseAdvanced />
                </div>

                        
                <validation-observer ref="observer" v-slot="{ handleSubmit }" >                  
                  <b-form @submit.prevent="handleSubmit(apply)" autocomplete="off">              
                    <validation-provider
                      name="NewPassword"
                      :rules="{ required: true, required: true, min: 5, max: 250 }"
                      v-slot="validationContext"
                    >
                      <b-form-group :label="$t('New Password')" class="text-12" >
                        <b-form-input
                          class="form-control-rounded password"
                          type="text"
                          autocomplete="off" readonly 
                          @focus="removeReadonly"
                          v-model="authorizePassword1"
                          @keypress="makeLoginOnEnter"
                          aria-describedby="input-1-live-feedback"
                          :state="getValidationState(validationContext)"
                          id="reset_password_1"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      name="NewPasswordConfirmation"
                      rules="required|password:@NewPassword"
                      v-slot="validationContext"
                    >
                    <b-form-group :label="$t('New Password Confirmation')" class="text-12">
                      <b-form-input
                        class="form-control-rounded password"
                        type="text"
                        autocomplete="off" readonly
                        @focus="removeReadonly"
                        v-model="authorizePassword2"
                        placeholder="password confirmation"                                                
                        @keypress="makeLoginOnEnter"
                        aria-describedby="input-2-live-feedback"
                        :state="getValidationState(validationContext)"
                        id="reset_password_2"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                    </validation-provider>
                   
                    <div class="input-group mb-3" style="margin-top: 30px; width: 100%">
                        <b-button
                          type="submit"
                          tag="button"
                          class="btn-rounded btn-block mt-2"
                          variant="primary mt-2"
                          id="reset_password_button"
                        >
                          {{ $t('Reset Password') }}
                        </b-button>                      
                    </div>
                     
                  </b-form>
                 </validation-observer>
           </form>
            
        </div>
    </div> 
    </div>          
        </div>
      </div>    
  </div>
</template>
<script>
import { AuthService } from "@/services/auth.service";
import { API_URL } from '@/.env';
import { extend } from 'vee-validate';
import { IMGService } from '@/services/img.service'
import ISALanguageSelector from '@/components/ISA/session/ISALanguageSelector';

extend('password', {
  params: ['target'],
  validate(value, { target }) {    
    return value === target;
  },
  message: 'Password confirmation does not match'
});


export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Forgot Password"
  },
  components: {      
      ISALanguageSelector,
  },
  data() {
    return {
      IMGService,      
      authorizePassword1: null,
      authorizePassword2: null,
      bgImage: IMGService.bgImage,
      logo: IMGService.isalogo,     
    };
  }, methods: {
    async onLoad() {       
      const token = this.$route.query ? this.$route.query.token : null;
      console.log(this.$route)
      console.log(this.$route.query)
      if(!token)
        return this.$router.push('/');
      try
      {
        const result = await AuthService.getResetPasswordRequest(API_URL.url, token);

      } catch(err) {
        this.$bvToast.toast(this.$t('An error occured when processing you reset password request. Please contact support.'), {
          title: this.$t('Reset Password'),
          variant: "warning",
          solid: true,
          duration: 5000
        });
        setTimeout(() => {          
          this.$router.push('/');
        }, 5000);          
      }
        
    },
    makeLoginOnEnter(e) {      
      if (e.keyCode === 13) {
        this.resetPassword();
      }
    },
    removeReadonly(e) {
        e.target.removeAttribute('readonly');                
    },
    async apply() {
      const token = this.$route.query ? this.$route.query.token : null;
      console.log(this.$route)
      console.log(this.$route.query)
      if(!token)
        return this.$router.push('/');
      let result = null;
      try
      {
        result = await AuthService.processResetPasswordRequest(API_URL.url, token, this.authorizePassword1);
      } catch(err) {
        result = err;
      }
      console.log(result)
      
      if(result.status === 200) {        
        this.$bvToast.toast(this.$t('New password has been set. Please sign in.'), {
          title: `Password Reset`,
          variant: "success",
          solid: true,
          duration: 5000,
        });
        setTimeout(() => {          
          this.$router.push('/app/sessions/signIn'); 
        }, 5000);
            
      } else if (result.status == 409 && result.code == 8304) {        
        this.$bvToast.toast(this.$t('This reset token has already been used. Please process the reset procedure again.'), {
          title: this.$t('Password Reset'),
          variant: "warning",
          solid: true,
          duration: 5000
        });
      }
    },
    getValidationState({ dirty, validated, valid = null }) {      
      return dirty || validated ? valid : null;
    },


  },
  mounted() {
    this.onLoad();
  }

};
</script>

<style lang="scss" scoped>
    .isa-primary {
        background-color: #36958d !important;
    }

    .user_card {
        height: 400px;
        width: 100%;
        margin-top: 50px;
        margin-bottom: auto;
        background: white;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .brand_logo {
        position: absolute;
        text-align: center;
        height: 55px;
    }

    .form_container {
        margin-top: 100px;
    }

    .login_btn {
        width: 100%;
        background: #36958d !important;
        color: white !important;
    }

        .login_btn:focus {
            box-shadow: none !important;
            outline: 0px !important;
        }

    .login_container {
        padding: 0 2rem;
    }

    .input-group-text {
        background: #36958d !important;
        color: white !important;
        border: 0 !important;
        border-radius: 0.25rem 0 0 0.25rem !important;
    }

    .input_user,
    .input_pass:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #c0392b !important;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #36958d;
        opacity: 0.6; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #36958d;
        opacity: 0.6; /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #36958d;
        opacity: 0.6; /* Firefox */
    }

    .btn:focus {
        outline: none !important;
    }

    .btn-secondary {
        border-color: white;
    }

    .nav-pills {
        background-color: white;
    }

    .text-12 {
        font-weight: bold;
        color: rgb(0, 0, 0);
    }

    .text-muted {
        font-weight: bold;
        color: rgb(87, 80, 80) !important;
    }

    .signinbtn {
        color: rgb(0, 0, 0);
        font-weight: bold;
    }

    @media screen and (max-width: 640px) {
        .text-muted {
            color: rgb(129, 122, 122) !important;
        }
    }

    .spinner.sm {
  height: 2em;
  width: 2em;
}

.auth-layout-wrap {    
    align-items: center;
    
}

@media screen and (max-width: 640px) {
    .auth-layout-wrap {
        justify-content: unset;
    }
}

.auth-layout-wrap .auth-content {
    max-width: 400px;
    margin: 5px;
}

.container{
  height: 100%;
  align-content: center;
}

.card{
  height: 385px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(236, 234, 234, 0.397) !important;
}

.isaloginbck {   
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  
  /* Background image is fixed in the viewport so that it doesn't move when 
     the content's height is greater than the image's height */
  background-attachment: fixed;
  
  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;
  
  /* Set a background color that will be displayed
     while the background image is loading */
  background-color: #464646;
}

  .password {
      -webkit-text-security:disc;
      font-family: text-security-disc;
  }
</style>